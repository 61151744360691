//const BaseURL = 'http://localhost:8080/';
//const URL = 'http://localhost:3000/';
//const GameURL='http://localhost:8091/';
//const ChatURL = 'http://localhost:8092/';

const GameURL='https://match4.pixiuverse.io/';
const BaseURL = 'https://test.pixiuverse.io/';
const URL = 'https://staging.pixiuverse.io/';
const ChatURL = 'https://chat.pixiuverse.io/';

//const GameURL='https://m4.pixiuverse.io/';
//const BaseURL = 'https://api.pixiuverse.io/';
//const URL = 'https://arena.pixiuverse.io/';
//const Chat = 'https://chat.pixiuverse.io/';

const api = {
    url:URL,
    GameURL:GameURL,
    ChatURL:ChatURL,
    BaseURL:BaseURL,
    //Public
    statistic:BaseURL +'api/statistic',
    player_online:GameURL +'count-online',
    loaderboard:BaseURL +'api/leaderboard',

    //Auth
    login:BaseURL +'api/login',
    register:BaseURL +'api/register',
    details:BaseURL +'api/user/account/details',
    edit_name:BaseURL +"api/user/account/edit-name",
    avatar:BaseURL +'api/user/account/avatar',

    pxt_report:BaseURL +'api/user/report/pxt',
    reward_report:BaseURL +'api/user/report/reward',

    check_member:BaseURL +'api/user/account/refcode/check',
    add_member_tree:BaseURL +'api/user/account/refcode/apply',

    friend_list:BaseURL +'api/user/friend-list',

    //Game Match 4
    match4_list:BaseURL +'api/games/match4/list',
    match4_create:BaseURL +'api/user/games/match4/create',
    match4_join:BaseURL +'api/user/games/match4/join',
    match4_details:BaseURL +'api/user/games/match4/details',
    match4_store_move:BaseURL +'api/user/games/match4/store-move',
    match4_cancel:BaseURL +'api/user/games/match4/cancel',
    match4_cancel_on_close:BaseURL +'api/user/games/match4/cancel-on-close',

    addchat:BaseURL +'api/user/chat/create',
    get_chat:ChatURL +'get-chat',
    get_pin:ChatURL +'get-pin',
    
};
export default api;