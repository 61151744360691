import React from "react";
import './../App.css';
import { io } from "socket.io-client";
import api from "../config/api";
import axios from "axios";
import { toast } from "react-toastify";

class  PanelWrap extends React.Component {
    constructor(props) {
        super(props);

        this.messages = [];

        this.state = {
            isactiveClass: false,
            messages:[],
            message:"",
            showdata : this.messages,
            is_show_wrape:false,
            messages_pin:{
                "_id": "64998977f9ef8946851b6e52",
                "sender": "Pixiuverse",
                "created_at": "2023-06-26T12:49:59.475Z",
                "message": "Hello World",
                "image": "default.png",
                "is_pin": true,
                "updated_at": "2023-06-26T12:49:59.486Z"
            },
        }

        this.messagesEnd=React.createRef();
    }

    async componentDidMount() {
        let self=this;
        let auth_token = localStorage.getItem('auth_token');
        if (auth_token) {
            this.setState({ auth_token: auth_token });
            this.getChats();
            this.getPin();
        }

        let user_id = localStorage.getItem('user');
        if (user_id) {
            let user=JSON.parse(user_id);
            this.setState({ user_id: user.user_id});
            this.setState({ avatar: user.avatar});
        }
        let that=this;
        try {
            var socket = io.connect(api.ChatURL);
                socket.on('connect', function (data) {
            });

            socket.on('message', function (data) {
                that.appendData(data);
            });
        }
        catch (e) {
        }
    }

    /**
     * Get Report
     */
    async getChats() {
        let that = this;
        await axios({
            method: 'post',
            url: api.get_chat,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                let m=ress.data.messages;
                that.setState({is_show_wrape:true})
                m.map(function(d,i){
                    that.appendData(d);
                });
                setTimeout(() => {
                    that.scrollToLast();
                },200);
            }
            else {
            }
        }).catch(function (e) {
           console.log(e);
        });
    }

     /**
     * Get Report
     */
     async getPin() {
        let that = this;
        await axios({
            method: 'post',
            url: api.get_pin,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (ress) {
            if (ress.data.error === 200) {
                let m=ress.data.messages;
                that.setState({is_show_wrape:true})
                m.map(function(d,i){
                    console.log(d);
                    that.setState({messages_pin:d});
                });
            }
            else {
            }
        }).catch(function (e) {
           console.log(e);
        });
    }

    addMessage(){
        let that = this;
        
        if (this.state.message == "") {
            toast.error("Enter Message");
            return 0;
        }
        this.setState({ isLoading: true });
        axios({
            method: 'post', url: api.addchat,
            data: {
                message: this.state.message,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': this.state.lang,
                'Authorization': 'Bearer ' + this.state.auth_token,
            }
        }).then(function (ress) {
            that.setState({ isLoading: false })
            if (ress.data.error == 200) {
                toast.success("Sent");
                that.setState({message:""});
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (e) {
            that.setState({ isLoading: false })
        });
    }

    appendData(d) {
        this.messages.push(
            <div className="messages-panel" key={d._id}>
                <div className="row align-items-center">
                    <div className="col-3">
                        <img src={api.BaseURL+"storage/avatar/"+d.image} alt=""/>
                    </div>
                    <div className="col-7">
                        <h3>{d.sender}</h3>
                        <p>{d.message}</p>
                    </div>
                </div>
            </div>
        );
        this.setState({
           showdata : this.messages,
        });
        setTimeout(() => {
            this.scrollToLast();
        });
    }

    scrollToLast(){
        this.messagesEnd.current.scrollIntoView({ behavior: "smooth",block: "end", inline: "nearest"  });
    }

    submited = (event) => {
        event.preventDefault();
        this.addMessage();
    }
    
    render() {
        return (
            <>
                <div className="panel-wrap" style={this.state.isactiveClass ? { marginRight: 0 } : {}}>
                    {
                        this.state.is_show_wrape?
                            <button className="btn btn-chat" type="button" onClick={() => this.setState({ isactiveClass: !this.state.isactiveClass })}>Chat Room</button>
                        :null
                    }
                    
                    <div className="chat">
                        <div className="chat-box">
                            <div className="header">
                                <div className="message-list">
                                    <div className="row">
                                        <div className="col">Chat</div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.messages_pin?
                                    <div className="MessageContainer">
                                        <div className="messages">
                                            <div className="messages-panel" key={10000}>
                                                <div className="row justify-content-end d-flex">
                                                    <small className="badge bg-warning float-end" style={{width:100,color:"#000"}}>Pin Message</small>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col-3">
                                                        <img src={api.BaseURL+"storage/avatar/default.png"} alt=""/>
                                                    </div>
                                                    <div className="col-7">
                                                        <h3>{this.state.messages_pin.sender}</h3>
                                                        <p>{this.state.messages_pin.message}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :null
                            }
                            <div className="MessageContainer" >
                                <div className="messages" style={{height:480,overflow:"scroll-x"}}>
                                    {
                                        this.state.showdata.map(function(d,i){
                                            return d;
                                        })
                                    }
                                    <div style={{ float:"left", clear: "both"}} key={"dsdsdsdsdsd"}
                                        ref={this.messagesEnd}>
                                    </div>
                                </div>
                            </div>

                            <div className="message-box">
                                <form onSubmit={this.submited}>
                                    <div className="mb-3">
                                        <input className="form-control" type="text" placeholder="Say something……" value={this.state.message} onChange={(e)=>this.setState({message:e.target.value})} maxLength={200}/>
                                    </div>
                                    <div className="text-end">
                                        <span className="sub">{this.state.message.length}/200</span>
                                        <button className="btn btn-subnit ms-1">
                                           
                                            {
                                                this.state.isLoading?
                                                    "Sending"
                                                :
                                                "Send"
                                            }
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PanelWrap;